import { graphql } from 'gatsby';
import React from 'react';
import { md2react } from 'helpers/parser';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ResourceGrid from 'components/molecules/ResourceGrid';
import CtaSection from 'components/organisms/CtaSection';
import CustomerStoryHero from 'components/organisms/CustomerStoryHero';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';

const CustomerStoryContent = ({
  title,
  description,
  featuredImage,
  logoImage,
  productsUsed,
  body,
}) => {
  return (
    <>
      <CustomerStoryHero
        title={title}
        description={description}
        imageUrl={featuredImage}
        logoUrl={logoImage}
        productsUsed={productsUsed}
      />
      <div className="u-section u-container px-6 lg:px-md">
        <div className="prose-xl lg:w-8/12">{md2react(body)}</div>
      </div>
    </>
  );
};

export const CustomerStoryPreview = ({ entry }) => {
  return <CustomerStoryContent {...entry.get('data').toJS()} />;
};

const CustomerStoryPage = ({
  data: {
    post: { frontmatter, rawMarkdownBody },
    related,
  },
}) => {
  const { seo, title, description, companyName } = frontmatter;

  const resources = related.edges.map(({ node }) => {
    const { title, slug, featuredImage, resourceType, description } =
      node.frontmatter || {};
    return {
      title: title,
      url: `/customer-stories/${slug}`,
      thumbnailUrl: featuredImage,
      type: resourceType,
      excerpt: description,
    };
  });

  return (
    <Layout headerInverted>
      <SEO
        title={seo?.title || title}
        description={seo?.description || description}
        openGraphImage={seo?.openGraphImage}
      />

      <CustomerStoryContent {...frontmatter} body={rawMarkdownBody} />

      <NewsletterCtaSection
        headline="Never miss a captivating success story. Send the latest stories to your inbox."
        background="bg-aqua text-white"
      />

      <ResourceGrid
        lead="Related Customers"
        title={
          companyName &&
          `${companyName} aren’t the only ones making waves with Skupos. Read on.`
        }
        cols={3}
        noIcon
        resources={resources}
      />

      <CtaSection headline="Unlock the power of 14,000+ retailers connected to Skupos" />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CustomerStoryByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        featuredImage
        logoImage
        description
        productsUsed
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
    related: allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: {
          templateKey: { eq: "customer-story" }
          status: { eq: "public" }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featuredImage
            resourceType
            description
          }
        }
      }
    }
  }
`;

export default CustomerStoryPage;
