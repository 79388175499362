import { Link } from 'gatsby';
import React from 'react';
import startCase from 'lodash/startCase';
import ArrowIcon from 'components/atoms/ArrowIcon';

interface CustomerStoryHeroProps {
  title: string;
  description: string;
  imageUrl: string;
  logoUrl?: string;
  productsUsed?: string[];
}

const CustomerStoryHero: React.FC<CustomerStoryHeroProps> = ({
  title,
  description,
  imageUrl,
  logoUrl,
  productsUsed,
}) => {
  return (
    <div className="bg-dark-grey pt-28 md:pt-48">
      <div className="u-container flex flex-col lg:flex-row items-center px-6 lg:px-md relative">
        <svg
          className="absolute bottom-0 left-1/2 w-40 h-auto -mx-20 -my-12 hidden lg:block"
          xmlns="http://www.w3.org/2000/svg"
          width="190"
          height="210"
          fill="none"
          viewBox="0 0 190 210"
        >
          <path
            fill="#923879"
            d="M112.362 149.864V.151H77.489v149.713l-52.31-52.31L.521 122.209l82.076 82.076a17.44 17.44 0 0024.655 0l82.076-82.076-24.656-24.655-52.311 52.31z"
            opacity="0.4"
          ></path>
        </svg>

        <div className="w-full lg:w-1/2 pb-16 lg:pb-36 lg:pr-16">
          <div className="mb-8 md:mb-16">
            <Link
              className="inline-flex items-center text-aqua font-semibold text-lg tracking-tighter hover:text-white focus:text-white transition"
              to="/customer-stories"
            >
              <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
              Customer Stories
            </Link>
          </div>
          {logoUrl && (
            <img src={logoUrl} alt={title} className="w-auto h-6 mb-8" />
          )}

          <h1 className="u-h1 text-white mb-4">{title}</h1>
          <p className="text-light-grey tracking-tighter text-lg md:text-xl">
            {description}
          </p>

          {productsUsed && (
            <div className="mt-12">
              <div className="uppercase text-aqua text-sm font-bold tracking-tighter mb-8">
                Products Used
              </div>
              <div className="flex items-center space-x-8">
                {productsUsed.map((product, index) => (
                  <div
                    key={index}
                    className="flex items-center text-white text-lg font-semibold tracking-tighter leading-none"
                  >
                    <div className="mr-4 w-5 h-5 bg-border-grey" />
                    <div className="pt-px">{startCase(product)}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {imageUrl && (
          <div className="w-full lg:w-1/2">
            <img src={imageUrl} alt={title} className="w-full h-auto" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerStoryHero;
